import React from 'react';
import {useControllerProps} from '../../ControllerContext';
import {SectionNotification} from 'wix-ui-tpa/SectionNotification';
import {useTranslation} from 'yoshi-flow-editor-runtime';
import Alert from 'wix-ui-icons-common/on-stage/Alert';
import s from './MinimumOrderAmount.scss';

export enum MinimumOrderAmountDataHook {
  Notification = 'MinimumOrderAmountDataHook.Notification',
  ContinueShopping = 'MinimumOrderAmountDataHook.ContinueShopping',
  NotificationText = 'MinimumOrderAmountDataHook.NotificationText',
}

export function MinimumOrderAmount() {
  const {continueShopping} = useControllerProps().navigationStore;
  const {
    cart: {minimumOrderAmount},
  } = useControllerProps().cartStore;
  const {t} = useTranslation();

  return (
    <div className={s.notification}>
      <SectionNotification type="alert" data-hook={MinimumOrderAmountDataHook.Notification}>
        <SectionNotification.Icon icon={<Alert className={s.icon} />} />
        <SectionNotification.Text>
          <span data-hook={MinimumOrderAmountDataHook.NotificationText}>
            {t('cart.note.minimumOrder.text', {
              minAmount: minimumOrderAmount.formattedConvertedValue,
              additionalAmount: minimumOrderAmount.formattedConvertedDiff,
            })}
          </span>
          <button
            data-hook={MinimumOrderAmountDataHook.ContinueShopping}
            className={s.link}
            type="button"
            onClick={() => continueShopping()}>
            {t('cart.note.minimumOrder.link')}
          </button>
        </SectionNotification.Text>
      </SectionNotification>
    </div>
  );
}
